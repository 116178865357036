import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientWorkoutResistanceExerciseSetRequestModel } from './workout-resistance-exercise-set.request-model';
import { ClientWorkoutResistanceExerciseSetResponseModel } from './workout-resistance-exercise-set.response-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class ClientWorkoutResistanceExerciseSetService
{
	public constructor(private http: HttpClient) { }

	public getByWorkoutResistanceExerciseId(workoutResistanceExerciseId: string): Observable<ClientWorkoutResistanceExerciseSetResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercise-sets/workout-resistance-exercise/${workoutResistanceExerciseId}`;

		return this.http.get<ClientWorkoutResistanceExerciseSetResponseModel[]>(url);
	}

	public create(request: ClientWorkoutResistanceExerciseSetRequestModel): Observable<ClientWorkoutResistanceExerciseSetResponseModel>
	{
		const url = environment.baseUrl + '/client/training/workout-resistance-exercise-set';

		return this.http.post<ClientWorkoutResistanceExerciseSetResponseModel>(url, request);
	}

	public update(request: ClientWorkoutResistanceExerciseSetRequestModel): Observable<ClientWorkoutResistanceExerciseSetResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercise-set/${request.workoutResistanceExerciseSetId}`;

		return this.http.put<ClientWorkoutResistanceExerciseSetResponseModel>(url, request);
	}

	public updateRange(request: ClientWorkoutResistanceExerciseSetRequestModel[]): Observable<ClientWorkoutResistanceExerciseSetResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercise-set`

		return this.http.put<ClientWorkoutResistanceExerciseSetResponseModel[]>(url, request);
	}
}
