import { Injectable } from '@angular/core';

import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';
import { BaseService } from '@fitness-central/api/base.service';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientWorkoutCreateRequestModel } from './workout-create.request-model';
import { ClientWorkoutRequestModel } from './workout.request-model';
import { ClientWorkoutResponseModel } from './workout.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientWorkoutService extends BaseService
{
	public constructor
	(
	)
	{
		super();
	}

	public get(workoutId: string): Observable<ClientWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout/${workoutId}`;

		return this.httpClient.get<ClientWorkoutResponseModel>(url);
	}

	public getAll(startDate: Date, endDate: Date, status: TrainingStatusType): Observable<ClientWorkoutResponseModel[]>
	{
		const startDateString = super.convertToLuxonDateTime(startDate);
		const endDateString = super.convertToLuxonDateTime(endDate);

		const url = environment.baseUrl + `/client/training/workouts?startDate=${startDateString}&endDate=${endDateString}&status=${status}`;

		return this.httpClient.get<ClientWorkoutResponseModel[]>(url);
	}

	public getAllByClientId(clientId: string, startDate: Date, endDate: Date, status: TrainingStatusType): Observable<ClientWorkoutResponseModel[]>
	{
		const startDateString = super.convertToLuxonDateTime(startDate);
		const endDateString = super.convertToLuxonDateTime(endDate);

		const url = environment.baseUrl + `/client/${clientId}/training/workouts?startDate=${startDateString}&endDate=${endDateString}&status=${status}`;

		return this.httpClient.get<ClientWorkoutResponseModel[]>(url);
	}

	public getByRoutineId(routineId: string): Observable<ClientWorkoutResponseModel[]>
	{
		let url = environment.baseUrl + `/client/training/workouts/routine/${routineId}`;

		return this.httpClient.get<ClientWorkoutResponseModel[]>(url);
	}

	public getByRoutineIdAndWorkoutStatus(routineId: string, workoutStatus: TrainingStatusType): Observable<ClientWorkoutResponseModel[]>
	{
		let url = environment.baseUrl + `/client/training/workouts/routine/${routineId}?status=${workoutStatus}`;

		return this.httpClient.get<ClientWorkoutResponseModel[]>(url);
	}

	public create(request: ClientWorkoutCreateRequestModel): Observable<ClientWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout`;

		return this.httpClient.post<ClientWorkoutResponseModel>(url, request);
	}

	public update(request: ClientWorkoutRequestModel): Observable<ClientWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout/${request.workoutId}`;

		return this.httpClient.put<ClientWorkoutResponseModel>(url, request);
	}

	public updateRange(request: ClientWorkoutRequestModel[]): Observable<ClientWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workouts`;

		return this.httpClient.put<ClientWorkoutResponseModel[]>(url, request);
	}
}
